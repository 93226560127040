var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    class: {
      frmBlock__layout: _vm.list.length > 0
    }
  }, [_c('draggable', {
    staticClass: "draggable-wrap",
    attrs: {
      "list": _vm.list,
      "delayOnTouchOnly": "true",
      "ghostClass": "DAV-block__item--active",
      "handle": ".DAV-block__supports--move",
      "animation": "200",
      "delay": "100"
    },
    on: {
      "end": function end($event) {
        return _vm.updateOption();
      }
    }
  }, _vm._l(_vm.list, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "DAV-block__item",
      class: {
        'DAV-block__item--hide': !item.enabled
      }
    }, [_c('div', {
      staticClass: "DAV-block__bullet"
    }, [item.imgURL ? _c('img', {
      staticClass: "DAV-block_img",
      attrs: {
        "src": item.imgURL
      }
    }) : _c('img', {
      staticClass: "DAV-block_img DAV-block_img_no",
      attrs: {
        "src": require("@/assets/images/common/icon_logo_grey.svg")
      }
    }), _c('span', [_vm._v("컴포넌트 숨김")])]), _c('button', {
      staticClass: "DAV-block__name",
      on: {
        "click": function click($event) {
          return _vm.openPopupDetail(index, item);
        }
      }
    }, [_vm._v(" " + _vm._s(item.alt) + " ")]), _c('div', {
      staticClass: "DAV-block__supports"
    }, [_c('button', {
      staticClass: "DAV-block__supports--move",
      attrs: {
        "oncontextmenu": "return false"
      }
    }, [_vm._v(" 이동 ")]), _c('button', {
      staticClass: "DAV-block__supports--option",
      on: {
        "click": function click($event) {
          return _vm.toggleOption(index, true);
        }
      }
    }, [_vm._v(" 컴포넌트 옵션 열기 ")])]), _c('transition', {
      attrs: {
        "name": "slide",
        "mode": "out-in"
      }
    }, [_c('div', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.optionVisible[index],
        expression: "optionVisible[index]"
      }],
      staticClass: "DAV-block__options"
    }, [_c('button', {
      staticClass: "DAV-block__options--delete",
      on: {
        "click": function click($event) {
          return _vm.imgDelete(index);
        }
      }
    }, [_c('span', [_vm._v("컴포넌트 삭제")])]), _c('button', {
      staticClass: "DAV-block__options--display",
      on: {
        "click": function click($event) {
          return _vm.imgEnabled(index, !item.enabled);
        }
      }
    }, [_c('span', [_vm._v("컴포넌트 숨김")])]), _c('button', {
      staticClass: "DAV-block__options--close",
      on: {
        "click": function click($event) {
          return _vm.toggleOption(index, false);
        }
      }
    }, [_c('span', [_vm._v("컴포넌트 옵션 닫기")])])])])], 1);
  }), 0)], 1), _c('div', {
    staticClass: "add-button",
    on: {
      "click": function click($event) {
        return _vm.openPopupDetail();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.list.length ? "+ \uCD94\uAC00(".concat(_vm.list.length, "/").concat(_vm.displayLength, ")") : "+ \uCD94\uAC00") + " ")])]);
}
var staticRenderFns = []

export { render, staticRenderFns }